/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { UserData } from './styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { capitalize, getFirstLetter } from '../../functions/index'

export default function UserDataComponent(props) {
    const getUserData = () => localStorage.getItem(`@${props.nomeSistema}-data`)
    const [dataUser] = useState(JSON.parse(getUserData()))
    const [authenticated] = useState(dataUser && dataUser.nome && dataUser.nome != '');

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        localStorage.removeItem(`@${props.nomeSistema}`)
        localStorage.removeItem(`@${props.nomeSistema}-data`)
    }

    const handleLogout = () => {
        logout()
        if (props.funcaoLogout) {
            props.funcaoLogout()
        } else {
            window.location.href = 'http://tcenet.tce.gti.br/'
        }
    }

    return authenticated ? 
    (
        <UserData id="UserData_UserData">
            <Button id="btn-userdata" aria-controls="userdata-menu" aria-haspopup="true" onClick={handleClick}>
                <UserData.Icon id="UserData_Icon"><PermIdentityOutlinedIcon/></UserData.Icon>
                <UserData.Name id="UserData_Name">{capitalize(dataUser.nome)}</UserData.Name>
                <UserData.CaretIcon id="UserData_CaretIcon"><ExpandMoreIcon/></UserData.CaretIcon>
            </Button>
            <Menu
                id="userdata-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} className="userdata-top-box">
                    <UserData.Container id="UserData_Container">
                        <UserData.LetterName id="UserData_LetterName">{getFirstLetter(dataUser.nome)}</UserData.LetterName>
                        <UserData.ContentInfo id="UserData_ContentInfo">
                            <UserData.NameUser id="UserData_NameUser">{capitalize(dataUser.nome)}</UserData.NameUser>
                            {
                                dataUser.setor != null && <UserData.NomeSetor id="UserData_NomeSetor">{capitalize(dataUser.setor)}</UserData.NomeSetor>
                            }
                            <UserData.Email id="UserData_Email">{dataUser.email}</UserData.Email>
                        </UserData.ContentInfo>
                    </UserData.Container>
                </MenuItem>
                <MenuItem onClick={() => handleLogout()} className="userdata-exit-app"><ExitToAppIcon/>Sair</MenuItem>
            </Menu>
        </UserData>
    ) :
    (
        <span id="UserData_ReservedArea"></span>
    )
}