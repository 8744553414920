/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from "react-router-dom"
import { Breadcrumb } from './styles'

export default function Breadcrumbs(props) {
  return (
    <Breadcrumb>
      {
        props.crumbs === undefined
          ?
          null
          :
          props.crumbs.map(({ name, path }, key) =>
            key + 1 === props.crumbs.length
              ?
              (
                <Link key={key} className="disabled actual" to="">
                  {name}
                </Link>
              )
              :
              (
                <Link key={key} to={path}>
                  {name}
                </Link>
              )
          )
      }
    </Breadcrumb>
  )
}
