/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */

export const capitalize = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    
    return splitStr.join(' '); 
}

export const getFirstLetter = (string) => {
    var str = string.trim().toUpperCase();
    var res = str.charAt(0);
    return res;
}