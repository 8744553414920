/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    :root {
        --primary: #3a5e96;
        --primary-dark: #142539;
        --secondary: #009f51;
        --tertiary: #e9ae01;
        --blue: #559bfb;
        --blue-variant: #6576ff;
        --indigo: #2c3782;
        --purple: #816bff;
        --pink: #ff63a5;
        --red: #e85347;
        --orange: #ffa353;
        --yellow: #f4bd0e;
        --green: #1ee0ac;
        --teal: #20c997;
        --cyan: #09c2de;
        --white: #fff;
        --gray-dark: #343a40;
        --success: #1ee0ac;
        --info: #09c2de;
        --warning: #f4bd0e;
        --danger: #e85347;
        --dark: #1c2b46;
        --gray: #8091a7;
        --light: #e5e9f2;
        --lighter: #f5f6fa;
        --alternative1: #526484;
        --alternative2: #6e82a5;
        --alternative3: #8094AE;
        --alternative4: #dbdfea;
        --alternative5: #F2F2F2;
        --alternative6: #364a63;
        --alternative7: #DDE7FB;
        --font-regular: 'Product Sans', sans-serif;
        --font-medium: 'Product Sans', sans-serif;
        --font-bold: 'Product Sans', sans-serif;
        --font-italic: 'Product Sans', sans-serif;
        --font-italic-bold: 'Product Sans', sans-serif;
        --poppins: 'Poppins', sans-serif;
    }

    body,
    html,
    #root {
        height: 100%;
        background-color: var(--white);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a,
    abbr,
    address,
    area,
    article,
    aside,
    audio,
    b,
    base,
    bdi,
    bdo,
    blockquote,
    body,
    br,
    button,
    caption,
    cite,
    code,
    col,
    colgroup,
    data,
    datalist,
    dd,
    del,
    details,
    dfn,
    dialog,
    div,
    dl,
    dt,
    em,
    embed,
    fieldset,
    figure,
    footer,
    form,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    head,
    header,
    hgroup,
    hr,
    html,
    i,
    iframe,
    img,
    input,
    ins,
    kbd,
    keygen,
    label,
    legend,
    li,
    link,
    main,
    map,
    mark,
    menu,
    menuitem,
    meta,
    meter,
    nav,
    object,
    ol,
    optgroup,
    option,
    output,
    p,
    param,
    pre,
    progress,
    q,
    rb,
    rp,
    rt,
    rtc,
    ruby,
    s,
    samp,
    section,
    select,
    small,
    source,
    span,
    strong,
    style,
    sub,
    summary,
    sup,
    table,
    tbody,
    td,
    template,
    textarea,
    tfoot,
    th,
    thead,
    time,
    title,
    tr,
    track,
    u,
    ul,
    var,
    video,
    wbr {
        font-family: var(--font-regular) !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body,
    a,
    p,
    h1,
    h2,
    h4,
    h4,
    h5,
    h6,
    button,
    div,
    .MuiTypography-body1 {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        outline: none !important;
    }

    ol,
    ul {
        list-style: none;
    }

    .txt-center {
        text-align: center !important;
    }

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .one-line-txt {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .bg-primario {
        background-color: var(--primary) !important;
    }

    .bg-secundario {
        background-color: var(--secondary) !important;
    }

    .bg-terciario {
        background-color: var(--tertiary) !important;
    }

    .bg-branco {
        background-color: var(--white) !important;
    }

    .bg-preto {
        background-color: var(--gray-dark) !important;
    }

    .bg-azul {
        background-color: var(--blue) !important;
    }

    .bg-indigo {
        background-color: var(--indigo) !important;
    }

    .bg-roxo {
        background-color: var(--purple) !important;
    }

    .bg-rosa {
        background-color: var(--pink) !important;
    }

    .bg-vermelho {
        background-color: var(--red) !important;
    }

    .bg-laranja {
        background-color: var(--orange) !important;
    }

    .bg-amarelo {
        background-color: var(--yellow) !important;
    }

    .bg-verde {
        background-color: var(--green) !important;
    }

    .bg-cinza {
        background-color: var(--cinza) !important;
    }

    .bg-alternativo-1 {
        background-color: var(--alternative1) !important;
    }

    .bg-alternativo-2 {
        background-color: var(--alternative2) !important;
    }

    .bg-alternativo-3 {
        background-color: var(--alternative3) !important;
    }

    .bg-alternativo-4 {
        background-color: var(--alternative4) !important;
    }

    .bg-alternativo-5 {
        background-color: var(--alternative5) !important;
    }

    .bg-alternativo-6 {
        background-color: var(--alternative6) !important;
    }

    .bg-alternativo-7 {
        background-color: var(--alternative7) !important;
    }

    .txt-primario {
        color: var(--primary) !important;
        font-weight: 600;
    }

    .txt-secundario {
        color: var(--secondary) !important;
        font-weight: 600;
    }

    .txt-    {
        color: var(--tertiary) !important;
        font-weight: 600;
    }

    .txt-branco {
        color: var(--white) !important;
        font-weight: 600;
    }

    .txt-preto {
        color: var(--gray-dark) !important;
        font-weight: 600;
    }

    .txt-azul {
        color: var(--blue) !important;
        font-weight: 600;
    }

    .txt-indigo {
        color: var(--indigo) !important;
        font-weight: 600;
    }

    .txt-roxo {
        color: var(--purple) !important;
        font-weight: 600;
    }

    .txt-rosa {
        color: var(--pink) !important;
        font-weight: 600;
    }

    .txt-vermelho {
        color: var(--red) !important;
        font-weight: 600;
    }

    .txt-laranja {
        color: var(--orange) !important;
        font-weight: 600;
    }

    .txt-amarelo {
        color: var(--yellow) !important;
        font-weight: 600;
    }

    .txt-verde {
        color: var(--green) !important;
        font-weight: 600;
    }

    .txt-cinza {
        color: var(--cinza) !important;
        font-weight: 600;
    }

    .txt-alternativo-1 {
        color: var(--alternative1) !important;
        font-weight: 600;
    }

    .txt-alternativo-2 {
        color: var(--alternative2) !important;
        font-weight: 600;
    }

    .txt-alternativo-3 {
        color: var(--alternative3) !important;
        font-weight: 600;
    }

    .txt-alternativo-4 {
        color: var(--alternative4) !important;
        font-weight: 600;
    }

    .txt-alternativo-5 {
        color: var(--alternative5) !important;
        font-weight: 600;
    }

    .txt-alternativo-6 {
        color: var(--alternative6) !important;
        font-weight: 600;
    }

    .txt-alternativo-7 {
        color: var(--alternative7) !important;
        font-weight: 600;
    }

    .border-color-primario {
        border-color: var(--primary) !important;
        border: 2px solid;
    }

    .border-color-secundario {
        border-color: var(--secondary) !important;
        border: 2px solid;
    }

    .border-color-terciario {
        border-color: var(--tertiary) !important;
        border: 2px solid;
    }

    .border-color-branco {
        border-color: var(--white) !important;
        border: 2px solid;
    }

    .border-color-preto {
        border-color: var(--gray-dark) !important;
        border: 2px solid;
    }

    .border-color-azul {
        border-color: var(--blue) !important;
        border: 2px solid;
    }

    .border-color-indigo {
        border-color: var(--indigo) !important;
        border: 2px solid;
    }

    .border-color-roxo {
        border-color: var(--purple) !important;
        border: 2px solid;
    }

    .border-color-rosa {
        border-color: var(--pink) !important;
        border: 2px solid;
    }

    .border-color-vermelho {
        border-color: var(--red) !important;
        border: 2px solid;
    }

    .border-color-laranja {
        border-color: var(--orange) !important;
        border: 2px solid;
    }

    .border-color-amarelo {
        border-color: var(--yellow) !important;
        border: 2px solid;
    }

    .border-color-verde {
        border-color: var(--green) !important;
        border: 2px solid;
    }

    .border-color-cinza {
        border-color: var(--cinza) !important;
        border: 2px solid;
    }

    .border-color-alternativo-1 {
        border-color: var(--alternative1) !important;
        border: 2px solid;
    }

    .border-color-alternativo-2 {
        border-color: var(--alternative2) !important;
        border: 2px solid;
    }

    .border-color-alternativo-3 {
        border-color: var(--alternative3) !important;
        border: 2px solid;
    }

    .border-color-alternativo-4 {
        border-color: var(--alternative4) !important;
        border: 2px solid;
    }

    .border-color-alternativo-5 {
        border-color: var(--alternative5) !important;
        border: 2px solid;
    }

    .border-color-alternativo-6 {
        border-color: var(--alternative6) !important;
        border: 2px solid;
    }

    .border-color-alternativo-7 {
        border-color: var(--alternative7) !important;
        border: 2px solid;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: var(--primary);
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #9ecaec;
    }
    ::-webkit-scrollbar-thumb:active {
        background: var(--primary);
    }
    ::-webkit-scrollbar-track {
        background: #ebebeb;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #ebebeb;
    }
    ::-webkit-scrollbar-track:active {
        background: #ebebeb;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
        max-height: 230px;
    }

    header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important;
        background: transparent !important;
    }

    .MuiChip-root.MuiChip-deletable {
        background-color: var(--primary) !important;
        color: var(--white);
        text-transform: uppercase;
        font-weight: 600;
    }

    svg.MuiSvgIcon-root.MuiChip-deleteIcon {
        color: var(--alternative4);
    }

    .MuiTable-root span.MuiButtonBase-root.MuiTableSortLabel-root {
        font-weight: bolder;
    }

    .MuiTable-root th.MuiTableCell-root.MuiTableCell-head {
        font-weight: bolder;
    }

    .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
        background: rgb(58 94 150 / 8%);
    }

    .MuiDialog-paperFullWidth, .MuiDialog-paperFullWidth {
        max-width: 100% !important;
        border-radius: 10px;
    }

    .MuiPaginationItem-page.Mui-selected {
        background: var(--primary);
        color: var(--white);
    }

    ul.MuiList-root.ListOfModalItems.MuiList-padding {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        justify-content: center;
    }

    .MuiDialog-root.DialogListOfModalItems .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
        max-width: 90% !important;
    }

    .MuiDialog-root.DialogListOfModalItems .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
    }

    .MuiDialog-root.DialogListOfModalItems .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded p.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock {
        text-transform: uppercase;
        font-size: 10px;
        color: var(--alternative3);
    }

    .MuiDialog-root.DialogListOfModalItems .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded .MuiAvatar-root.MuiAvatar-circle.MuiAvatar-colorDefault {
        background: var(--alternative2);
    }

    .MuiDialog-root.DialogListOfModalItems .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded ul.MuiList-root.ListOfModalItems.MuiList-padding {
        max-height: 477px;
        overflow: auto;
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
        color: var(--primary);
    }

    .MuiAppBar-colorPrimary {
        background-color: var(--primary) !important;
    }

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnDefaultLoading svg {
        color: var(--white);
    }

    .MuiButton-root {
        font-weight: 600;
    }

    .MuiPickersDay-daySelected {
        background: var(--primary);
    }

    .MuiPickersModal-dialogRoot .MuiDialogActions-root.MuiDialogActions-spacing button:nth-child(1n){
        background-color: grey;
        color: var(--white);
        padding: 6px 14px;
    }

    .MuiPickersModal-dialogRoot .MuiDialogActions-root.MuiDialogActions-spacing button:nth-child(2n){
        background-color: var(--primary);
        color: var(--white);
        padding: 6px 14px;
    }

    .MuiPickersToolbar-toolbar {
        background: var(--primary);
    }

    .MuiPickersClockPointer-pointer {
        background: var(--primary);
    }

    .MuiPickersClockPointer-noPoint {
        background: var(--primary);
        border-color: var(--primary);
    }

    .MuiPickersClockPointer-thumb {
        border-color: var(--primary);
    }

    .MuiDialogActions-root {
        padding: 20px;
    }

    .MuiDialogActions-root button {
        margin-left: 15px !important;
        padding-left: 20px;
        padding-right: 20px;
    }

    .MuiDialog-paperScrollPaper {
        border-radius: 7px !important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background: #fff;
        padding: 0 8px 0 0;
    }

    span.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnCloseSnackBar {
        background: transparent;
        padding: 5px;
        min-width: 0;
        border-radius: 100%;
    }

    #list-icons-component .h2 {
        min-height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-white);
        border-radius: var(--border-radius-md);
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
        border: 2px solid transparent;
        font-size: 1.6em;
        width: 100%;
    }

    #list-icons-component {
        display: grid;
        grid-auto-rows: auto;
        grid-auto-columns: -webkit-max-content;
        grid-auto-columns: max-content;
        grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
        grid-column-gap: var(--space-2);
        grid-row-gap: var(--space-2);
        text-align: center;
        margin: 35px 0 0 0;
    }

    #list-icons-component .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - 20px);
        margin: 0 0 30px 0;
    }

    #list-icons-component .name {
        margin-top: .5rem;
        font-size: .7em;
        overflow: hidden;
        -webkit-user-select: all;
        -moz-user-select: all;
        user-select: all;
    }

    span.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnCloseSnackBar svg.MuiSvgIcon-root {
        color: #fff;
    }

    .inputFile {
        display: none;
    }

    @media (max-width: 800px) {
        ul.MuiList-root.ListOfModalItems.MuiList-padding {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    /*SWITCH IOS*/

.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 61px;
    height: 26px;
    background-color: #bdbdbd;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}

.form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 58px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}

.form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked+i::after {
    transform: translate3d(16px, 2px, 0);
}

.form-switch input {
    display: none;
}

.form-switch input:checked+i {
    background-color: var(--primaria);
}

.form-switch input:checked+i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked+i::after {
    transform: translate3d(37px, 2px, 0);
}

div#userdata-menu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    width: 280px;
    border: 1px solid #d3d4d5;
    border-top: 3px solid var(--primary);
    border-radius: 8px;
}

div#userdata-menu li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.userdata-exit-app.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    height: 47px;
    position: relative;
}

div#userdata-menu li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.userdata-exit-app.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button svg {
    margin: 0 11px 0 1px;
}

#notification-menu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    width: 280px;
    border: 1px solid #d3d4d5;
    border-top: 3px solid var(--primary);
    border-radius: 8px;
    min-height: 268px;
}

#notification-menu .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded ul.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
}

#notification-menu li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.title-view-all-notifications.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    background: var(--white) !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    color: #292929;
    border-bottom: 1px solid #dedede;
    padding: 15px;
}

#notification-menu li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.btn-view-all-notifications.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid #dedede;
    padding: 15px;
}

#notification-menu li.content-subitem-menu-notifications {
    width: 100%;
    overflow: auto;
    height: 164px;
}
`