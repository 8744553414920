import styled from 'styled-components'

export const Menu = styled.div`
    div#nested-list-subheader {
        font-size: 11px;
        /* line-height: 1.2; */
        letter-spacing: 0.2em;
        color: #8094ae;
        text-transform: uppercase;
        font-weight: 700;
    }

    li.MuiListItem-root.MuiListItem-gutters {
        transition: auto;
    }

    li.MuiListItem-root.MuiListItem-gutters:hover span.MuiTypography-root.MuiListItemText-primary.MuiTypography-displayBlock {
        color: var(--primary) !important;
    }

    li.MuiListItem-root.MuiListItem-gutters:hover svg.MuiSvgIcon-root {
        color: var(--primary);
    }

    li.MuiListItem-root.MuiListItem-gutters svg.MuiSvgIcon-root {
        transition: all ease .35s;
        color: #a5a5a5;
    }

    li.MuiListItem-root.MuiListItem-gutters  span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
        transition: all ease .35s;
        color: #a5a5a5;
    }

    li.MuiListItem-root.MuiListItem-gutters {
        cursor: pointer;
        position: relative;
    }

    li.MuiListItem-root.MuiListItem-gutters svg#expand-icon-menu {
        position: absolute;
        left: -5px;
        font-size: 17px;
    }

    li.MuiListItem-root.MuiListItem-gutters {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    a.active li.MuiListItem-root.MuiListItem-gutters span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock,
    a.active li.MuiListItem-root.MuiListItem-gutters svg.MuiSvgIcon-root {
        color: var(--primary);
    }

    a {
        text-decoration: none;
    }
`

Menu.Drawer = styled.div`
    li.MuiListItem-root.MuiListItem-gutters svg#expand-icon-menu {
        left: 2px !important;
    }
`

Menu.DrawerInfoUser = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 25px 20px;
    border-bottom: 1px solid #e8e8e8;
`

Menu.DrawerLogo = styled.img`
    width: 100%;
    height: auto;
`

Menu.DrawerIcon = styled.div`
    background: var(--alternative4);
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 25px 0 12px 0;

    svg {
        font-size: 30px;
        color: var(--alternative1);
    }
`

Menu.DrawerInfo = styled.div`
    width: 100%;
`

Menu.DrawerName = styled.div`
    font-weight: 600;
    font-size: 15px;
    color: var(--alternative1);
`

Menu.DrawerEmail = styled.div`
    font-size: 12px;
    margin: 3px 0 0 0;
    color: #9c9c9c;
    white-space: -moz-pre-wrap;
    white-space: -hp-pre-wrap;
    white-space: -o-pre-wrap;
    white-space: -pre-wrap;
    white-space: pre-wrap;
    white-space: pre-line;
    word-wrap: break-word;
    word-break: break-all;
`