import React, { Fragment } from "react"
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { Menu } from './styles'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ListSubheader from '@material-ui/core/ListSubheader'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }))

function SidebarItem({ item, subItem, index }) {
    const classes = useStyles()
    const [collapsed, setCollapsed] = React.useState(true)
    const { descricao, icone, rota, color, subMenus } = item

    function toggleCollapse() {
        setCollapsed((prevValue) => !prevValue)
    }

    function onClick() {
        if (Array.isArray(subMenus)) {
            toggleCollapse()
        }
    }

    let expandIcon
    if (Array.isArray(subMenus) && subMenus.length) {
        expandIcon = !collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />
    }

    
  return (
    <Fragment key={index + subMenus.length}> 
        {
            subMenus.length === 0
            ?
                <NavLink activeClassName="active" to={rota}>
                    <ListItem className={classes.nested} data-item={item} data-subitem={subItem} data-index={index} >
                        <div id="expandIconCollapse" >{expandIcon}</div>
                        <ListItemIcon><Menu.IconMenu color={color}>{icone}</Menu.IconMenu></ListItemIcon>
                        <ListItemText  primary={descricao} />
                    </ListItem>
                </NavLink>
            :
                <ListItem onClick={onClick}  className={classes.nested} data-item={item} data-subitem={subItem} data-index={index}>
                    <div id="expandIconCollapse" >{expandIcon}</div>
                    <ListItemIcon><Menu.IconMenu color={color}>{icone}</Menu.IconMenu></ListItemIcon>
                    <ListItemText primary={descricao} />
                </ListItem>
        }
        
        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
            {Array.isArray(subMenus) ? (
            <List disablePadding dense>
                {subMenus.map((subItem, index) => (
                    <SidebarItem key={`${subItem.id}${index}`} item={subItem} subItem={subItem} index={index} />
                ))}
            </List>
            ) : null}
        </Collapse>
    </Fragment>
  )
}

function Sidebar({ subMenus }) {
    const classes = useStyles()

  return (
    <Menu id="Menu">
      <List 
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
            <ListSubheader component="div" id="nested-list-subheader">MENU</ListSubheader>
        }
        className={classes.root}
      >
        {subMenus.map((sidebarItem, index) => (
          <SidebarItem
            key={`${sidebarItem.descricao}${index}`}
            item={sidebarItem}
          />
        ))}
      </List>
    </Menu>
  )
}

export default Sidebar