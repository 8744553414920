/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import styled from 'styled-components'

export const Breadcrumb = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
    text-decoration: none;

    @media (max-width: 993px) {
        padding: 0 14px 0 0px;
        margin: 6px 0 40px 0;
    }

    & a {
        font-weight: 600;
        color: var(--gray);
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:first-child:before {
            display: none
        }

        &:before {
            font-family: 'Material Icons';
            content: "keyboard_arrow_right";
            color: var(--primary);
            vertical-align: top;
            display: inline-block;
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 20px;
            margin: 0 10px 0 8px;
            -webkit-font-smoothing: antialiased;
            top: 1px;
            position: relative;
        }
    }

    & a.disabled.actual {
        color: var(--primary);
        pointer-events: none;
    }

    & a svg {
        margin: 0 4px;
    }
`