/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React from 'react'
import { NavBar } from './styles'
import UserData from '../../elements-ui/UserData/index'
import Notification from '../../elements-ui/Notification'
import Drawer from '../../elements-ui/Drawer'
import { Link } from 'react-router-dom'

export default function NavBarComponent(props) {
    return (
        <NavBar id="NavBar">
            <NavBar.Content id="Content">
                <NavBar.LeftContent id="NavBar_LeftContent">
                    <Drawer
                        nomeSistema={props.nomeSistema}
                        ImgLogoTCEGO={props.ImgLogoTCEGO}
                        dataMenus={props.dataMenus}
                    />
                    <Link to="/"><NavBar.Logo id="Logo"><NavBar.ImgLogo id="ImgLogo" src={props.logoSistema} /></NavBar.Logo></Link>
                </NavBar.LeftContent>
                <NavBar.Actions id="Actions">
                    <Notification notificacoes={props.notificacoes} />
                    <UserData nomeSistema={props.nomeSistema} funcaoLogout={props.funcaoLogout} />
                </NavBar.Actions>
            </NavBar.Content>
        </NavBar>
    )
}