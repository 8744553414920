/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import styled from 'styled-components'

export const ContainerPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`

ContainerPage.Content = styled.div`
    max-width: 1220px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    
    @media (max-width: 1260px) {
        width: calc(100% - 40px);
    }
`

ContainerPage.Menu = styled.div`
    padding: 2rem 0;
    width: 220px;
    flex-shrink: 0;
    position: static;
    background: transparent;
    opacity: 1;
    visibility: visible;
    margin-right: 48px;
    border: none;
    transform: none;
    max-height: none;
    min-height: none;

    @media (max-width: 993px) {
        display: none;
    }
`

ContainerPage.ContentPage = styled.div`
    padding: 2rem 0 0;
    width: calc(100% - (220px + 48px));

    @media (max-width: 993px) {
        width: 100%;
    }
`