/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { Notification } from './styles'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import NotNotification from '../../assets/img/not-notifications.png'
import { v4 as uuidv4 } from 'uuid'

export default function NotificationComponent(props) {
    const [viewPageNotification, setViewPageNotification] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [toUrl, setToUrl] = useState('')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleRedirect = (url) => {
        setAnchorEl(null)
        setToUrl(url)
        setViewPageNotification(true)
    }

    return (
        props.notificacoes
        ?
            <Notification id="Notification">
                { viewPageNotification ? <Redirect to={toUrl} /> : null }

                <IconButton className="txt-primario icon-button-notification" component="span" aria-controls="userdata-menu" aria-haspopup="true" onClick={handleClick}>
                    <Notification.Mark id="Notification_Mark"/>
                    <NotificationsNoneIcon />
                </IconButton>
                <Menu
                    id="notification-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem className="title-view-all-notifications">Notificações</MenuItem>
                    <Notification.MenuItemList className="content-subitem-menu-notifications">
                        <Notification.Menu id="Notification_Menu">
                            {
                                props.notificacoes.length === 0
                                ?
                                    <Notification.MenuItem id="Notification_MenuItem" style={{ height: '163px' }}>
                                        <Notification.NoMessage id="Notification_NoMessage">
                                            <img src={NotNotification} alt=""/>
                                            Sem notificações
                                        </Notification.NoMessage>
                                    </Notification.MenuItem>
                                :
                                    props.notificacoes.map(obj => (
                                        <Notification.MenuItem key={uuidv4()} id="Notification_MenuItem"  className="waves-effect waves-blue-light" onClick={() => handleRedirect(obj.url)}>
                                            <Notification.MenuItemIcon id="Notification_MenuItemIcon"><NotificationsNoneIcon/></Notification.MenuItemIcon>
                                            <Notification.Info>
                                                <Notification.MenuItemTitle id="Notification_MenuItemTitle">{obj.descricao}</Notification.MenuItemTitle>
                                                <Notification.MenuItemHour id="Notification_MenuItemHour">{obj.data}</Notification.MenuItemHour>
                                            </Notification.Info>
                                        </Notification.MenuItem>
                                    ))
                            }
                        </Notification.Menu>
                    </Notification.MenuItemList>
                    <MenuItem onClick={handleRedirect} className="btn-view-all-notifications">Ver Todas</MenuItem>
                </Menu>
            </Notification>
        :
            null
    )
}