/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

class ColoredLinearProgress extends Component {
  render() {
    const { classes } = this.props;
    return <LinearProgress {...this.props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>;
  }
}

const styles = props => ({
  colorPrimary: {
    backgroundColor: 'var(--secondary)',
  },
  barColorPrimary: {
    backgroundColor: 'var(--alternative4)',
  }
});

export default withStyles(styles)(ColoredLinearProgress);