/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import styled from 'styled-components'

export const NavBar = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #e5e9f2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    z-index: 5;

    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnMenuMobile {
        background: #fff;
        padding: 0;
        min-width: 0;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        margin: 0 10px 0 0;
    }

    @media (min-width: 994px) {
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnMenuMobile {
            display: none;
        }
    }

    @media (max-width: 993px) {
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btnMenuMobile {
            display: flex;
        }
    }
`

NavBar.Content = styled.div`
    max-width: 1220px;
    width: 100%;
    height: 66px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1260px ) {
        padding: 0 20px;
    }
`

NavBar.Logo = styled.div`
    max-width: 275px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

NavBar.ImgLogo = styled.img`
    width: 100%;
    height: auto;
`

NavBar.Actions = styled.div`
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
`

NavBar.LeftContent = styled.div`
    display: flex;
    flex-direction: row;
`