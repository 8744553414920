/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from "react"
import Drawer from "../Draw"
import "@reach/dialog/styles.css"
import { createHistory } from "@reach/router"
import MenuContent from '../../navigation/Menu'
import { Menu } from './styles'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import Button from '@material-ui/core/Button'
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'
import MenuDrawer from '../../navigation/MenuSubLevel'

const history = createHistory(window)

function useLocation() {
  const [pathname, setPathname] = useState(history.location.pathname)
  useEffect(
    () => {
      return history.listen(() => setPathname(history.location.pathname))
    },
    [history]
  )
  return pathname
}

export default function App(props) {
    const getUserData = () => localStorage.getItem(`@${props.nomeSistema}-data`)
    const [dataUser] = useState(JSON.parse(getUserData()))
    const [opened, setOpened] = useState(false)
    const open = useCallback(() => setOpened(true), [])
    const close = useCallback(() => setOpened(false), [])
    const location = useLocation()

    useEffect(
        () => {
        setOpened(false)
        },
        [location]
    )

    return (
      <div className="App">
        <Button className="btnMenuMobile" onClick={open}><MenuOutlinedIcon/></Button>
        <Drawer open={opened} onDismiss={close}>
            <Menu.Drawer>
                <Menu.DrawerInfoUser id="Menu_DrawerInfoUser">
                <Menu.DrawerLogo id="Menu_DrawerLogo" src={props.ImgLogoTCEGO} />
                <Menu.DrawerIcon id="Menu_DrawerIcon"><PersonOutlineOutlinedIcon/></Menu.DrawerIcon>
                <Menu.DrawerInfo id="Menu_DrawerInfo">
                    <Menu.DrawerName id="Menu_DrawerName">{ dataUser.nome === '' ? '-' : dataUser.nome }</Menu.DrawerName>
                    <Menu.DrawerEmail id="Menu_DrawerEmail">{ dataUser.email === '' ? '-' : dataUser.email }</Menu.DrawerEmail>
                </Menu.DrawerInfo>
                </Menu.DrawerInfoUser>
                <MenuDrawer subMenus={props.dataMenus} />
            </Menu.Drawer>
        </Drawer>
      </div>
    )
}