/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import styled from 'styled-components'

export const Notification = styled.div`
    span.MuiButtonBase-root.MuiIconButton-root.txt-primario.icon-button-notification {
        position: relative;
    }

    span.MuiButtonBase-root.MuiIconButton-root.txt-primario.icon-button-notification span.MuiIconButton-label {
        position: relative;
    }

    #notification-menu li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.title-view-all-notifications.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
        background: var(--white) !important;
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        user-select: none;          /* Likely future */
        cursor: default;
        font-size: 14px;
        font-weight: 500;
        color: #292929;
        border-bottom: 1px solid #dedede;
    }

    #notification-menu li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.btn-view-all-notifications.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
        text-align: center;
        align-items: center;
        justify-content: center;
        color: var(--primary);
        font-size: 14px;
        font-weight: 500;
        border-top: 1px solid #dedede;
    }
`

Notification.Mark = styled.div`
    background: red;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    right: 1px;
    top: 2px;
`

Notification.Menu = styled.div`
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: column; */
    /* overflow: auto; */
    height: 126px;
`

Notification.Info = styled.div`
    display: flex;
    flex-direction: column;
    margin: 14px 0;
`

Notification.MenuItemList = styled.li`

    li.content-subitem-menu-notifications {
        width: 279px;
        display: block;
        padding: 0;
        margin: 0;
        overflow: auto;
        height: 164px;
    }
`

Notification.MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    /* padding: 9px 8px; */
    border-bottom: 1px solid #dedede;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

Notification.MenuItemIcon = styled.div`
    background: var(--primary);
    color: var(--white);
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px 0 12px;
`

Notification.MenuItemTitle = styled.div`
    font-size: 13px;
`

Notification.MenuItemHour = styled.div`
    font-size: 11px;
    color: #8094ae;
`

Notification.NoMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 13px;
    padding: 8px 0 0 0;
    color: #a2a2a2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    img {
        width: 98px;
        margin: -25px 0 5px 0;
    }
`