/* eslint-disable prettier/prettier */
import React from "react"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import { useSpring, animated } from "react-spring"
import { v4 as uuid } from 'uuid'

function useDelayClose(open) {
  const [delayClose, setDelayClose] = React.useState(false)
  const [everOpen, setEverOpen] = React.useState(false)
  React.useEffect(
    () => {
      if (!open) {
        setDelayClose(true)
        const timeout = setTimeout(() => {
          setDelayClose(false)
        }, 600)
        return () => clearTimeout(timeout)
      } else {
        setEverOpen(true)
        setDelayClose(true)
      }
    },
    [open]
  )
  return delayClose && everOpen
}

export default function Drawer(props) {
  const { left } = useSpring({ left: props.open ? 0 : -300 })
  const delayClose = useDelayClose(props.open)
  return (
    <DialogOverlay
      isOpen={props.open || delayClose}
      onDismiss={props.onDismiss}
      id="DialogOverlay"
      style={{ zIndex: 999 }}
    >
      <DialogContent
        style={{
          width: 0,
          height: 0,
          padding: 0,
          margin: 0
        }}
        id="DialogContent"
        aria-label={`dialog-content-${uuid()}`}
      >
        <animated.div
          style={{
            position: "fixed",
            width: 300,
            top: 0,
            left: left,
            bottom: 0,
            padding: 0,
            margin: 0,
            backgroundColor: "white"
          }}
          id="animated_div"
        >
          {props.children}
        </animated.div>
      </DialogContent>
    </DialogOverlay>
  )
}