/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import styled from 'styled-components'

export const UserData = styled.div`
    width: auto;
    
    button#btn-userdata span.MuiButton-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 764px) {
        button#btn-userdata {
            background: var(--white);
            min-width: 0;
            padding: 0;
            border-radius: 100%;
            margin: 0 0 0 10px;
            width: 48px;
            height: 48px;
        }
    }
`

UserData.Icon = styled.div`
    color: #fff;
    width: 32px;
    height: 32px;display: flex;font-size: 14px;
    background: var(--primary);align-items: center;
    flex-shrink: 0;font-weight: 500;border-radius: 50%;letter-spacing: 0.06em;
    justify-content: center;
`

UserData.Name = styled.div`
    margin: 0 10px;
    color: var(--alternative1);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    text-transform: capitalize !important;

    @media (max-width: 764px) {
        display: none;
    }
`

UserData.CaretIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 764px) {
        display: none;
    }
`

UserData.Container = styled.div`
    display: flex;
    padding: 10px 3px;
    align-items: center;
    flex-direction: row;
    justify-content: center;
`

UserData.LetterName = styled.div`
    color: var(--white);
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 14px;
    background: var(--primary);
    align-items: center;
    flex-shrink: 0;
    font-weight: 500;
    border-radius: 50%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    justify-content: center;
`

UserData.ContentInfo = styled.div`
    width: 183px;
    display: flex;
    overflow: hidden;
    text-align: left;
    padding-left: 14px;
    flex-direction: column;
`

UserData.NameUser = styled.div`
    color: #364a63;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 14px;
    white-space: pre-wrap;
    text-transform: capitalize;
`

UserData.NomeSetor = styled.div`
    color: #364a63;    
    font-size: 0.75rem;
    font-weight: 700;
    font-style: italic;
    line-height: 14px;
    white-space: pre-wrap;
    text-transform: capitalize;
    margin: 6px 0 0 0;
`

UserData.Email = styled.div`
    color: #8094ae;
    font-size: 12px;
    text-transform: lowercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`
